
import { defineComponent } from "vue"
import AdminHeader from "@/components/admin/AdminHeader.vue"
import AdminSidebar from "@/components/admin/AdminSidebar.vue"

export default defineComponent({
  name: "Dashboard",
  components: {
    AdminSidebar,
    AdminHeader,
  },
  props: {},
  setup: function () {
    return {}
  },
})
